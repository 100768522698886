const initialState = {
    // linkPantheonCompany: 0,
    itemsTableState: { globalFilter: "", hiddenColumns: ["id", "anrebate"], pageSize: 10, sortBy: [] },
    // isMobile: false,
    // loading: false,
    // history: []
    // propertys added on user
    // profil_offers: true,
    // profil_customers: true,
    // profil_products: true,
    // profil_gratis: true
}

const profilKlettReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LINK_PANTHEON_COMPANY': 
            return { ...state, linkPantheonCompany: action.payload }
        case 'SET_ITEMS_TABLE_STATE':
            return { ...state, itemsTableState: action.payload }
        case 'LOGOUT':
            return { ...initialState }
        default: {
            return state;
        }
    }
}

export default profilKlettReducer;