import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useHashCrypt from '../../helpers/useHashCrypt';
import queryString from 'query-string';
import t from '../../helpers/Translation';
import jwt_decode from "jwt-decode";
import { navigate } from '../../helpers/routerHistory';
// import { thirdPartyLoginRegister } from '../../store/appStore/actions/userActions';
import { loginUser, activateUser, resetUserPassword, resendActivateUserMail } from '../../store/appStore/actions/userActions'; // checkHostOrganizationParams
// import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'; // custom FB button
import _ from 'lodash';

import Input from '../../components/form/Input';
// import TabButton from '../../components/button/TabButton';
// import Switch from '../../components/button/Switch';
// import IconTextButton from '../../components/button/IconTextButton';
import TextIconButton from '../../components/button/TextIconButton';
import Checkbox from '../../components/form/Checkbox';
import * as Styled from "./style";
import * as palette from '../../style/Variables';
import { toast } from 'react-toastify';
import AlertSimple from '../../components/alert/AlertSimple';
// import logoSign from '../../img/anywhere.png';
import logoSign from '../../img/profil.jpeg';
import logo from '../../img/profil-large.jpeg';
import logoMobile from '../../img/profil-large.jpeg';
import brokenSign from '../../img/profil.jpeg';
// import Register from './Register';
import ModalAlert from '../../components/common/ModalAlert';
import AlertModal from '../../components/alert/AlertModal';
import ConfirmModal from '../../components/modal/ConfirmModal';


const Login = () => {
    const rememberMeStoreState = useSelector(state => state.user.rememberMe.checked)
    const rememberedUsername = useSelector(state => state.user.rememberMe.username)
    const rememberedPassword = useHashCrypt(useSelector(state => state.user.rememberMe.password), false)
    const strings = useSelector(state => state.user.langStrings)
    const userStoreLang = {
        language: useSelector(state => state.user.language),
        languageVersion: useSelector(state => state.user.languageVersion)
    }
    const dispatch = useDispatch()
    const isMounted = useRef(true)

    const [ username, setUsername ] = useState(rememberMeStoreState ? rememberedUsername : '')
    const [ password, setPassword ] = useState(rememberMeStoreState ? rememberedPassword : '')
    const [ rememberMe, setRememberMe ] = useState(rememberMeStoreState)
    const [ errorLine, setErrorLine ] = useState({username: false, password: false})

    // const [ organization, setOrganization ] = useState({})
    // const [ onlyLogin, setOnlyLogin ] = useState(false)
    const [ loginOrRegister, setLoginOrRegister ] = useState('login') // eslint-disable-line

    // const [ showGoogle, setShowGoogle ] = useState(false)
    // const [ showFacebook, setShowFacebook ] = useState(false)

    const [ resetPassEmailField, setResetPassEmailField ] = useState('')
    const [ resetPassEmailFieldError, setResetPassEmailFieldError ] = useState(false)

    const [ changePassValue, setchangePassValue ] = useState('')
    const [ confirmChangePassValue, setConfirmChangePassValue ] = useState('')
    const [ resendActivationMail, setResendActivationMail ] = useState({modal: false, btn: false})

    // costBook user guest store data
    const costBookUserGuestData = useSelector(state => state.costBook.arhitektiGuestCostBookData)


    // on Enter press listener
    useEffectOnce(() => {
        const onKeyDown = (event) => event.key === "Enter" && isMounted.current && onSubmit(event)
        document.addEventListener('keydown', onKeyDown);
        return () => document.removeEventListener('keydown', onKeyDown)
    })

    // user activation failed email alert
    const [ showEmailAlert, setShowEmailAlert ] = useState(false)
    const activationEmailAlert = <ModalAlert
        title={t('User activation error', strings)}
        text={t('Resend your verification email', strings)}
        confirmBtnText={t('Send', strings)}
        icon={brokenSign}
        onConfirm={() => {
            setShowEmailAlert(false)
            const { email } = queryString.parse(window.location.search)
            activateUser(email)
            .then((res) => {
                if(res) {
                    toast.success((<AlertSimple icon={palette.x} text={t('Email sent!', strings)} />))
                    navigate('/')
                }
            })
            .catch(err => {
                console.log(err)
                toast.error((<AlertSimple icon={palette.x} text={t('Email send error', strings)} />))
                setShowEmailAlert(true)
            })
        }} 
        cancelBtnText={t('Cancel', strings)}
        showCancel={true}
        onCancel={() => {
            setShowEmailAlert(false)
        }}
    />

    // user password reset alert
    const [ showPasswordAlert, setShowPasswordAlert ] = useState(false)
    const enterPasswordContent = <div className="alert_box">
        <Input
            icon={palette.lock}
            type={'password'}
            name={'password'}
            placeholder={t('New password', strings)}
            autoComplete={"password"}
            value={changePassValue}
            onChange={(e) => setchangePassValue(e.target.value)}
            requiredField={changePassValue !== '' ? false : true}
        />
        <Input
            icon={palette.lock}
            type={'password'}
            name={'password'}
            placeholder={t('Confirm new password', strings)}
            autoComplete={"password"}
            value={confirmChangePassValue}
            onChange={(e) => setConfirmChangePassValue(e.target.value)}
            requiredField={confirmChangePassValue !== '' ? false : true}
        />
    </div>
    const passwordEmailAlert = <AlertModal
        type="password"
        title={t('Reset password', strings)}
        text={enterPasswordContent}
        icon={palette.lock}
        onConfirm={() => {
            if(changePassValue === '' || changePassValue.length < 6) return toast.error((<AlertSimple icon={palette.x} text={t('Fill required data', strings)} />))
            if(changePassValue !== confirmChangePassValue) return toast.error((<AlertSimple icon={palette.x} text={t('Password not equal', strings)} />))
            setShowPasswordAlert(false)
            const { email, passwordToken } = queryString.parse(window.location.search)
            resetUserPassword(email, passwordToken, changePassValue)
            .then((res) => {
                if(res) {
                    toast.success((<AlertSimple icon={palette.x} text={t('Password Changed', strings)} />))
                    navigate('/')
                }
            })
            .catch(err => {
                console.log(err)
                toast.error((<AlertSimple icon={palette.x} text={t('Password change error', strings)} />))
                setShowPasswordAlert(true)
            })
        }}
        confirmBtnText={t('Reset', strings)}
        onCancel={() => setShowPasswordAlert(false)}
        cancelBtnText={t('Cancel', strings)}
        showCancel={true}
    />

    // user email for password reset
    const [ showEnterEmail, setShowEnterEmail ] = useState(false)
    const enterEmailContent = <div className="alert_box">
        <span className="alert_text">{t('Enter your e-mail', strings)}</span>
        <Input
            icon={palette.email}
            type={'E-mail'}
            name={'email'}
            placeholder={'E-mail'}
            autoComplete={"value"}
            value={resetPassEmailField}
            onChange={(e) => setResetPassEmailField(e.target.value)}
            requiredField={resetPassEmailFieldError && resetPassEmailField === '' ? true : false}
        />
    </div>
    const showEnterEmailAlert = <AlertModal
        type="email"
        title={t('Forgot Password', strings)}
        text={enterEmailContent}
        icon={palette.email}
        onConfirm={() => {
            if(resetPassEmailField === '') return setResetPassEmailFieldError(true)
            setShowEnterEmail(false)
            resetUserPassword(resetPassEmailField)
            .then((res) => {
                if(res) {
                    toast.success((<AlertSimple icon={palette.x} text={t('Email sent!', strings)} />))
                    navigate('/')
                }
            })
            .catch(err => {
                console.log(err)
                toast.error((<AlertSimple icon={palette.x} text={t('Email send error', strings)} />))
                setShowEnterEmail(true)
            })
        }}
        confirmBtnText={t('Confirm', strings)}
        onCancel={() => {
            setShowEnterEmail(false)
            setResetPassEmailFieldError(false)
            setResetPassEmailField('')
        }}
        cancelBtnText={t('Cancel', strings)}
        showCancel={true}
    />


    useEffect(() => {
        return () => {isMounted.current = false}
    }, [])


    useEffect(() => {
        // checkHostOrganizationParams(window.location.hostname)
        // .then((res) => {
            // console.log('resssss', res)
            // if(res !== undefined && res != null && res.length > 0) {
            //     isMounted.current && setOrganization(res[0])
                // register password
                // if(res[0].hasOwnProperty('register') && res[0].register === '1') {
                //     isMounted.current && setOnlyLogin(false)
                // }
                // google register/login
                // if(res[0].hasOwnProperty('google') && res[0].google !== null && res[0].google !== '') {
                //     isMounted.current && setShowGoogle(true)
                // }
                // facebook register/login
                // if(res[0].hasOwnProperty('facebook') && res[0].facebook !== null && res[0].facebook !== '') {
                //     isMounted.current && setShowFacebook(true)
                // }
        //     }
        // })
        // .catch(err => {
        //     console.log(err)
        //     setLoginOrRegister('login')
        // })

        // check query string
        const isActivateUser = window.location.pathname.includes('activate') ? true : false
        const isPassReset = window.location.pathname.includes('reset') ? true : false
        const { email, activationToken, passwordToken } = queryString.parse(window.location.search)

        // activate user
        if(email && activationToken && isActivateUser) {
            activateUser(email, activationToken)
            .then((res) => {
                if(res) {
                    toast.success((<AlertSimple icon={palette.x} text={t('User activated, Login now!', strings)} />))
                    navigate('/')
                }
            })
            .catch(err => {
                console.log(err)
                setShowEmailAlert(true)
            })
        }

        // reset user password
        if(email && passwordToken && isPassReset) setShowPasswordAlert(true)
	}, [strings])


    const handleChange = (e) => {
        // console.log('handleChange()', e.target.value)
        e.target.name === 'username' ? setUsername(e.target.value) : setPassword(e.target.value)
        setResendActivationMail({modal: false, btn: false})
    }

    const handleRememberMe = () => {
        isMounted.current && setRememberMe(!rememberMe)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        loginUser({username, password, rememberMe}, dispatch, actionMsg, userStoreLang)
    }

    const actionMsg = (msg) => {
        let msgShow = "Error"
        if(msg === 'USER_DOES_NOT_EXIST') msgShow = "User does not exist!"
        if(msg === 'USER_NOT_ACTIVATED') {
            msgShow = "User not activated!"
            setResendActivationMail({btn: true})
        }
        if(msg === 'WRONG_PASSWORD') {
            msgShow = "Wrong password!"
            isMounted.current && setErrorLine({username: false, password: true})
        }
        toast.error((<AlertSimple icon={palette.x} text={t(msgShow, strings)} />))
    }

    const handleResetActivationEmail = (username) => {
        setResendActivationMail({modal: false, btn: true})
        resendActivateUserMail(username)
        .then((res) => {
            console.log(res)
            if(res) toast.success((<AlertSimple icon={palette.x} text={t('Email successfully sent!', strings)} />))
        })
        .catch(err => {
            console.log(err)
            toast.success((<AlertSimple icon={palette.x} text={t('Email error!', strings)} />))
        })
    }

    // const handleLoginOrRegister = (state) => {
    //     if(state) {
    //         isMounted.current && setLoginOrRegister('login')
    //     } else {
    //         isMounted.current && setLoginOrRegister('register')
    //     }
    // }
    
    // const responseGoogle = (response) => {
    //     if(response.profileObj && response.tokenId) {
    //         let email = response.profileObj['email']
    //         let password = response.tokenId
    //         let name = response.profileObj['name']
    //         let authtype = 'google'
    //         thirdPartyLoginRegister(email, password, dispatch, actionMsg, userStoreLang, organization, name, authtype)
    //     }
    // }

    // const responseFacebook = (response) => {
    //     if(response.email && response.name && response.accessToken) {
    //         let email = response.email
    //         let password = response.accessToken
    //         let name = response.name
    //         let authtype = 'facebook'
    //         thirdPartyLoginRegister(email, password, dispatch, actionMsg, userStoreLang, organization, name, authtype)
    //     }
    // }

    const handleForgetPassword = () => {
        setShowEnterEmail(true)
    }


    // let randomId = Math.floor((Math.random() * 10000) + 1)
    const { costBookId, jwt } = queryString.parse(window.location.search)
    // this hook render only once
	useEffectOnce(() => {
        // console.log('uEO: custom hook: FIRST TIME')
        // console.log('costBookId, jwt', costBookId, jwt)
        if(costBookId && jwt ) {
            let decoded = jwt_decode(jwt)
            let authtype = 'jwt'
            let username = decoded.email
            let password = jwt
            let rememberMe = false
            let redirect = '/cost-book-user-guest'
            let costBookUserGuestDataLocal = _.cloneDeep(costBookUserGuestData)
            costBookUserGuestDataLocal.costBookId = costBookId
            dispatch({ type: 'SET_COSTBOOK_GUEST', payload: costBookUserGuestDataLocal })
            loginUser({username, password, rememberMe, authtype, redirect}, dispatch, actionMsg, userStoreLang)
        }
    })



    return(
        <Styled.containerWrapper>
            <div className="login_row">

                {/* RESEND EMAIL VERIFICATION */}
                {showEmailAlert && activationEmailAlert}

                {/* ENTER EMAIL FOR RESET PASSWORD */}
                {showEnterEmail && showEnterEmailAlert}

                {/* ENTER RESET PASSWORD */}
                {showPasswordAlert && passwordEmailAlert}

                {/* LOGIN */}
                <div className="login_col_xl_6">
                    <Styled.LogoSignStyle src={ logoSign } />

                    {loginOrRegister === 'login' && <div>
                        <h1>{t('Login!', strings)}</h1>
                        {/* <form onSubmit={onSubmit}> */}
                        {/* <form onSubmit={onSubmit} autoComplete="off"> */}
                        <form onSubmit={onSubmit}>
                            <div className="mb-10">

                                {/* <input type="hidden" value="something"/> */}

                                {/* <form> */}
                                {/* <input type="password" style={{"width": 0, "height": 0, "visibility": "hidden", "position":"absolute", "left":0, "top":0}}/> */}
                                <Input
                                    icon={palette.envelope}
                                    type={'text'}
                                    name={'username'}
                                    value={username}
                                    placeholder={t('Username', strings)}
                                    onChange={handleChange}
                                    autoComplete={'username'}
                                    // autoComplete="off"
                                    errorBottomLine={errorLine.username}
                                />
                                {/* </form> */}
                            </div>
                            <Input
                                icon={palette.laLock}
                                type={'password'}
                                name={'password'}
                                value={password}
                                placeholder={t('Password', strings)}
                                onChange={handleChange}
                                autoComplete={'current-password'}
                                // autoComplete="off"
                                errorBottomLine={errorLine.password}
                            />
                            <div className="row remember_box">
                                <div className="col-md-6 text_left text_mobile_center display_inline_block">
                                    <Checkbox
                                        checked={rememberMe}
                                        value={rememberMe}
                                        label={t('Remember me', strings)}
                                        onChange={handleRememberMe}
                                    />
                                </div>
                                <div className="col-md-6 text_right text_mobile_center display_inline_block">
                                    <div className="text_link" onClick={handleForgetPassword}>{t('Forgot Password', strings) + '?'}</div>
                                </div>
                            </div>
                            <TextIconButton type="submit" actionClick={onSubmit} icon={palette.login} btnText={t('Login', strings)} bgd={'primary'} tooltip={t('Login', strings)}></TextIconButton>
                        </form>

                        {/* Resend activation email */}
                        {resendActivationMail.btn && <TextIconButton type="submit" actionClick={() => setResendActivationMail({modal: true, btn: true})} icon={palette.email} btnText={t('Resend Email', strings)} bgd={'primary'} tooltip={t('Resend Email', strings)}></TextIconButton>}
                        {resendActivationMail.modal && <ConfirmModal 
                            close={() => setResendActivationMail({modal: false, btn: true})}
                            title={"Resend email"}
                            question={t('Resend activation email?', strings)}
                            action={() => handleResetActivationEmail(username)}
                        />}

                    </div>}

                    {/* REGISTER */}
                    {/* {loginOrRegister === 'register' && <Register organization={organization} />} */}
                    {/* {loginOrRegister === 'register' && <Register />} */}
                    
                    <div className="row">
                        <div className="col-md">
                            {/* Goggle */}
                            {/* {isMounted.current && showGoogle && <GoogleLogin
                                clientId={organization.google}
                                buttonText={t('Login', strings)}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                                // custom Google button
                                render={renderProps => (
                                    <IconTextButton disabled={renderProps.disabled} actionClick={renderProps.onClick} icon={palette.googlePlus} btnText={t('Sign in', strings)} bgd={'google min_w_110'} tooltip={t('Google Sign in', strings)}></IconTextButton>
                                )}
                            />} */}

                            {/* Facebook */}
                            {/* {showFacebook && <FacebookLogin
                                appId={organization.facebook}
                                autoLoad={false}
                                fields="name,email,picture"
                                // onClick={componentClicked}
                                callback={responseFacebook} 
                                // custom FB button
                                render={renderProps => (
                                    <IconTextButton actionClick={renderProps.onClick} icon={palette.facebook} btnText={t('Sign in', strings)} bgd={'facebook min_w_110'} tooltip={t('Facebook Sign in', strings)}></IconTextButton>
                                )}
                            />} */}
                            {/* <Switch
                                value={true}
                                onChange={handleLoginOrRegister}
                                leftValue={t('Login', strings)}
                                rightValue={t('Register', strings)}
                                width={110}
                            /> */}
                        </div>
                    </div>
                </div>

                <div className="login_col_xl_6 login_right_side">
                    <Styled.LogoStyle src={ logo } />
                    <Styled.LogoMobileStyle src={ logoMobile } />
                </div>
            </div>
        </Styled.containerWrapper>
    )
}


// custom hook
function useEffectOnce(cb) {
    const once = useRef(false)
    useEffect(() => {
        if(!once.current) {
            cb();
            once.current = true
        }

    })
}

export default Login;